define("discourse/plugins/discourse-policy/discourse/components/policy-reminder-input", ["exports", "discourse-i18n", "select-kit/components/combo-box", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _discourseI18n, _comboBox, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const VALID_REMINDERS = [{
    id: "daily",
    name: (0, _discourseI18n.i18n)("daily")
  }, {
    id: "weekly",
    name: (0, _discourseI18n.i18n)("weekly")
  }];
  const PolicyReminderInput = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <ComboBox
      @value={{@reminder}}
      @content={{VALID_REMINDERS}}
      @onChange={{@onChangeReminder}}
    />
  
  */
  {
    "id": "SHUcaC+F",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@value\",\"@content\",\"@onChange\"],[[30,1],[32,1],[30,2]]],null],[1,\"\\n\"]],[\"@reminder\",\"@onChangeReminder\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-policy/discourse/components/policy-reminder-input.js",
    "scope": () => [_comboBox.default, VALID_REMINDERS],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "policy-reminder-input:PolicyReminderInput"));
  var _default = _exports.default = PolicyReminderInput;
});