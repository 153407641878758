define("discourse/plugins/discourse-policy/discourse/initializers/add-policy-builder", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-policy/discourse/components/modal/policy-builder"], function (_exports, _pluginApi, _policyBuilder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializePolicyBuilder(api, container) {
    const currentUser = api.getCurrentUser();
    const siteSettings = container.lookup("service:site-settings");
    const modal = container.lookup("service:modal");
    if (currentUser) {
      api.addComposerToolbarPopupMenuOption({
        label: "discourse_policy.builder.attach",
        icon: "file-signature",
        group: "insertions",
        action: toolbarEvent => {
          modal.show(_policyBuilder.default, {
            model: {
              insertMode: true,
              post: null,
              toolbarEvent
            }
          });
        },
        condition: () => !siteSettings.policy_restrict_to_staff_posts || currentUser.staff
      });
    }
  }
  var _default = _exports.default = {
    name: "add-discourse-policy-builder",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (siteSettings.policy_enabled) {
        (0, _pluginApi.withPluginApi)("1.13.0", api => initializePolicyBuilder(api, container));
      }
    }
  };
});