define("discourse/plugins/discourse-policy/discourse/initializers/extend-for-policy", ["exports", "@ember/object", "discourse/lib/plugin-api", "discourse/lib/utilities", "discourse-i18n", "discourse/plugins/discourse-policy/discourse/components/post-policy", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _object, _pluginApi, _utilities, _discourseI18n, _postPolicy, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SETTINGS = [{
    name: "groups"
  }, {
    name: "version"
  }, {
    name: "renew"
  }, {
    name: "renew-start",
    camelName: "renewStart"
  }, {
    name: "reminder"
  }, {
    name: "accept",
    default: (0, _discourseI18n.i18n)("discourse_policy.accept_policy"),
    escape: true
  }, {
    name: "revoke",
    default: (0, _discourseI18n.i18n)("discourse_policy.revoke_policy"),
    escape: true
  }];
  const PolicyWrapper = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <PostPolicy @post={{@data.post}} @policy={{@data.policy}} />
  
  */
  {
    "id": "Ss96fqZL",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@post\",\"@policy\"],[[30,1,[\"post\"]],[30,1,[\"policy\"]]]],null],[1,\"\\n\"]],[\"@data\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-policy/discourse/initializers/extend-for-policy.js",
    "scope": () => [_postPolicy.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "extend-for-policy:PolicyWrapper"));
  function _buildPolicyAttributes(policy) {
    const form = {};
    SETTINGS.forEach(setting => {
      form[setting.name] = policy.dataset[setting.camelName || setting.name] || setting.default || "";
      if (setting.escape) {
        form[setting.name] = (0, _utilities.escapeExpression)(form[setting.name]);
      }
    });
    if (!form.version || parseInt(form.version, 10) < 1) {
      form.version = 1;
    }
    form.private = policy.dataset.private === "true";
    return _object.default.create(form);
  }
  function attachPolicy(cooked, helper) {
    const policy = cooked.querySelector(".policy");
    if (!policy) {
      return;
    }
    policy.innerHTML = `<div class="policy-body">${policy.innerHTML}</div>`;
    const post = helper?.getModel();
    if (!post) {
      // if no post it means we are decorating the preview, make it clear it's a policy
      const policyPreview = document.createElement("div");
      policyPreview.classList.add("policy-preview");
      policyPreview.innerText = (0, _discourseI18n.i18n)("discourse_policy.title");
      policy.prepend(policyPreview);
      return;
    }
    helper.renderGlimmer(policy, PolicyWrapper, {
      post,
      policy: _buildPolicyAttributes(policy)
    });
  }
  var _default = _exports.default = {
    name: "extend-for-policy",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.7", function (api) {
        api.decorateCookedElement(attachPolicy, {
          onlyStream: false,
          id: "discourse-policy"
        });
        api.registerCustomPostMessageCallback("policy_change", (controller, message) => {
          controller.appEvents.trigger("policy:changed", {
            controller,
            message
          });
        });
      });
    }
  };
});